import type {
  ComponentData,
  ComponentModel,
  Project,
  ToddleLocation,
} from './ComponentModel'
import { applyFormula } from './formula/formula'
import { parseQuery } from './router'
import { renderComponent } from './runtime'
import { signal } from './signal'
import { memoize } from './util'
import UrlPattern from 'url-pattern'

const [hash] = window.location.hash.split('?')

window.toddle.locationSignal = signal<ToddleLocation>({
  query: parseQuery(window.location.search),
  path: window.location.pathname,
  hostname: window.location.hostname,
  hash: hash.slice(1),
})

const urlSignal = window.toddle.locationSignal.map(
  ({ query, path, hash }) =>
    `${path}${hash === '' ? '' : '#' + hash}?${Object.entries(query)
      .map(([key, value]) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
      })
      .join('&')}`,
)

urlSignal.subscribe((url) => {
  window.history.replaceState({}, '', url)
})

const main = () => {
  const root = document.getElementById('App')
  if (!root) {
    throw new Error("Cant find node with id 'App'")
  }
  fetch(`${window.location.pathname}.json`)
    .then((res) => res.json())
    .then(
      ({ components }: { components: ComponentModel[]; project: Project }) => {
        const component = components.find((comp) => {
          if (typeof comp.page !== 'string') {
            return false
          }
          const pattern = new UrlPattern(comp.page)
          return pattern.match(window.location.pathname)
        })
        if (!component || !component.root) {
          throw new Error(
            `Could not render component ${window.location.pathname}`,
          )
        }

        ;(window as any).toddlePage = component?.page
        ;(window as any).toddleComponents = components
        window.toddle.components = components
        const urlPattern = new UrlPattern(component.page ?? '')

        const attributesSignal = window.toddle.locationSignal.map(
          ({ query, path }) => {
            const params = urlPattern.match(path)
            return {
              ...Object.fromEntries(
                component.props.map((p) => [
                  p.name,
                  query[p.name] ?? p.initialValue,
                ]),
              ),
              ...params,
            }
          },
        )

        const dataSignal = signal<ComponentData>({
          Session: (window as any).toddle_user_session,
          Location: window.toddle.locationSignal.get(),
          Variables: Object.fromEntries(
            component.variables.map((variable) => [
              variable.name,
              applyFormula(variable.initialValue, {
                Props: attributesSignal.get(),
              }),
            ]),
          ),
          Props: attributesSignal.get(),
          Queries: Object.fromEntries(
            component.queries.map((q) => [
              q.name,
              { data: null, isLoading: false, error: null },
            ]),
          ),
          Functions: Object.fromEntries(
            component.functions?.map((f) => [
              f.name,
              memoize((data: ComponentData) => applyFormula(f.value, data)),
            ]) ?? [],
          ),
        })

        ;(window as any).__components = {
          ...((window as any).__components ?? {}),
          [component.name]: dataSignal,
        }

        attributesSignal.subscribe((Attributes) =>
          dataSignal.update((data) => ({
            ...data,
            Props: Attributes,
          })),
        )

        const elements = renderComponent({
          id: '0',
          component,
          components,
          isRootComponent: true,
          dataSignal,
          children: [],
          onEvent: (event: string, data: unknown) =>
            console.info('EVENT FIRED', event, data),
        })
        elements.forEach((elem) => {
          root.appendChild(elem)
        })
      },
    )
}

main()
