export const isObject = (input: any): input is Record<string, any> =>
  typeof input === 'object' && input !== null

export const mapObject = <T, T2>(
  object: Record<string, T>,
  f: (kv: [string, T]) => [string, T2],
): Record<string, T2> => Object.fromEntries(Object.entries(object).map(f))

export const mapValues = <T, T2>(
  object: Record<string, T>,
  f: (value: T) => T2,
): Record<string, T2> => mapObject(object, ([key, value]) => [key, f(value)])

export const omit = (object: Object, keys: string[]) =>
  Object.fromEntries(
    Object.entries(object).filter(([k]) => keys.includes(k) === false),
  )

export const groupBy = <T>(items: T[], f: (t: T) => string) =>
  items.reduce<Record<string, T[]>>((acc, item) => {
    const key = f(item)
    acc[key] = acc[key] ?? []
    acc[key].push(item)
    return acc
  }, {})

export const last = <T>(items: T[]) => items[items.length - 1]

export const toBoolean = (value: any) =>
  value !== false && value !== undefined && value !== null

export const uuid = () => {
  //@ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  )
}

export const memoize = <Args extends Array<unknown>, Result>(
  f: (...args: Args) => Result,
): ((...args: Args) => Result) => {
  let cache: { result: Result; args: Args } | null = null

  return (...args: Args) => {
    if (cache && shallowCompareLists(cache.args, args)) {
      return cache.result
    }
    cache = {
      args,
      result: f(...args),
    }
    return cache.result
  }
}

const shallowCompareLists = (a: Array<unknown>, b: Array<unknown>) =>
  a.length === b.length && a.every((item, index) => item === b[index])

export const isInputTaget = (event: Event) => {
  const target = event.target
  if (target instanceof HTMLElement) {
    if (
      target.tagName === 'INPUT' ||
      target.tagName === 'TEXTAREA' ||
      target.tagName === 'SELECT' ||
      target.tagName === 'STYLE-EDITOR'
    ) {
      return true
    }
    if (target.contentEditable?.toLocaleLowerCase() === 'true') {
      return true
    }
  }
  return false
}
