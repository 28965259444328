import { mapObject, mapValues, memoize, toBoolean } from './util'
import type {
  ComponentData,
  ComponentModel,
  ComponentQuery,
  NodeData,
  ToddleLocation,
} from './ComponentModel'
import { ActionModel } from './EventModel'
import { applyFormula, isFormula } from './formula/formula'
import {
  ComponentNodeModel,
  ElementNodeModel,
  NodeModel,
  SlotNodeModel,
  TextNodeModel,
} from './NodeModel'
import { print as printQuery } from 'graphql/language/printer'
import { signal, Signal } from './signal'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import { getClassName } from './hash'

declare global {
  interface Window {
    toddle: {
      registerAction: (name: string, handler: ActionHandler) => void
      registerFormula: (
        name: string,
        handler: FormulaHandler,
        getArgumentInputData?: (
          items: unknown[],
          index: number,
          input: any,
        ) => NodeData,
      ) => void
      getAction: (name: string) => ActionHandler | undefined
      getFormula: (name: string) => FormulaHandler | undefined
      getArgumentInputData: (
        name: string,
        items: unknown[],
        index: number,
        input: any,
      ) => NodeData
      data: Record<string, unknown>
      components?: ComponentModel[]
      locationSignal: Signal<ToddleLocation>
      eventLog: Array<{
        component: string
        node: string
        nodeId: string
        event: string
        time: number
        data: any
      }>
    }
  }
}

declare global {
  interface Element {
    cleanUp?: () => void
  }
}

;(window as any).logState = () => {
  console.table(
    Object.entries((window as any).__components).map(([name, sig]) => {
      return {
        name,
        ...(sig as any).get(),
      }
    }),
  )
}
;(window as any).nodesCreated = 0
export type ActionHandler = (
  args: unknown[],
  ctx: ComponentContext,
  event?: Event,
) => void

export type FormulaHandler = (args: unknown[], data: NodeData) => void

export const createQuery = (
  query: ComponentQuery,
  ctx: ComponentContext,
): { refetch: Function; destroy: Function } => {
  const payloadSignal = ctx.dataSignal.map((data) => {
    return JSON.stringify({
      url: query.url ? applyFormula(query.url, data) : undefined,
      _api: query._api,
      method: query.method ?? 'POST',
      body: query.body
        ? applyFormula(query.body, data)
        : {
            query: query.query ?? printQuery(query.documentNode as any),
            variables: mapValues(query.variables ?? {}, (value) =>
              applyFormula(value.value, data),
            ),
          },
    })
  })

  const execute = (body: string) => {
    if (
      query.condition &&
      !applyFormula(query.condition, ctx.dataSignal.get())
    ) {
      return
    }
    ctx.dataSignal.set({
      ...ctx.dataSignal.get(),
      Queries: {
        ...ctx.dataSignal.get().Queries,
        [query.name]: {
          data: ctx.dataSignal.get().Queries?.[query.name]?.data ?? null,
          isLoading: true,
          error: null,
        },
      },
    })
    fetch(
      `/_query/${encodeURIComponent(ctx.component.name)}.${encodeURIComponent(
        query.name,
      )}`,
      {
        method: 'POST',
        body,
      },
    )
      .then((res) => {
        if (res.ok) {
          return res.json()
        } else throw new Error('Error')
      })
      .then((res) => {
        ctx.dataSignal.set({
          ...ctx.dataSignal.get(),
          Queries: {
            ...ctx.dataSignal.get().Queries,
            [query.name]: {
              data: res.data,
              isLoading: false,
              error: res.errors,
            },
          },
        })
        query.onCompleted?.actions?.forEach((action) => {
          handleAction(action, ctx.dataSignal.get(), ctx)
        })
      })
      .catch((error) => {
        console.error('Error', query.name, error)
        ctx.dataSignal.set({
          ...ctx.dataSignal.get(),
          Queries: {
            ...ctx.dataSignal.get().Queries,
            [query.name]: {
              data: null,
              isLoading: false,
              error: error,
            },
          },
        })
        query.onFailed?.actions?.forEach((action) => {
          handleAction(action, ctx.dataSignal.get(), ctx)
        })
      })
  }
  const trigger =
    typeof query.debounce === 'number'
      ? debounce(execute, query.debounce)
      : typeof query.throttle === 'number'
      ? throttle(execute, query.throttle)
      : execute

  payloadSignal.subscribe(trigger)
  return {
    refetch: () => {
      trigger(payloadSignal.get())
    },
    destroy: () => payloadSignal.destroy(),
  }
}
export const createMutation = (
  query: ComponentQuery,
  ctx: ComponentContext,
) => {
  const triggerFunction = (variables: Record<string, any>) => {
    ctx.dataSignal.set({
      ...ctx.dataSignal.get(),
      Queries: {
        ...ctx.dataSignal.get().Queries,
        [query.name]: {
          ...(ctx.dataSignal.get().Queries?.[query.name] ?? {
            data: null,
            error: null,
          }),
          isLoading: true,
        },
      },
    })
    return fetch(`/_query/${query.name}`, {
      method: 'POST',
      body: JSON.stringify({
        url: query.url,
        _api: query._api,
        method: 'POST',
        body: {
          query: query.query ?? printQuery(query.documentNode as any),
          variables,
        },
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json()
        } else throw new Error('Error')
      })
      .then((res) => {
        ctx.dataSignal.set({
          ...ctx.dataSignal.get(),
          Queries: {
            ...ctx.dataSignal.get().Queries,
            [query.name]: {
              data: res.data,
              isLoading: false,
              error: res.error,
            },
          },
        })
        return res.data
      })
  }

  ctx.dataSignal.set({
    ...ctx.dataSignal.get(),
    Queries: {
      ...ctx.dataSignal.get().Queries,
      [query.name]: {
        data: null,
        isLoading: false,
        error: null,
      },
    },
  })

  let lastTriggerTime = Date.now()
  let debounceTimer: any | undefined = undefined
  return (variables: any) => {
    if (
      typeof query.throttle === 'number' &&
      query.throttle !== 0 &&
      Date.now() - lastTriggerTime < query.throttle
    ) {
      return
    }
    if (typeof query.debounce === 'number' && query.debounce !== 0) {
      clearTimeout(debounceTimer)
      return new Promise((resolve, reject) => {
        debounceTimer = setTimeout(
          () => triggerFunction(variables)?.then(resolve, reject),
          query.debounce as number,
        )
      })
    }
    return triggerFunction(variables)
  }
}

export type ComponentContext = {
  component: ComponentModel
  components: ComponentModel[]
  isRootComponent: boolean
  dataSignal: Signal<ComponentData>
  triggerEvent: (event: string, data: unknown) => void
  mutations: Record<string, Function>
  queries: Record<string, { refetch: Function; destroy: Function }>
  children: Element[]
}

export type ActionContext = {
  dataSignal: Signal<NodeData>
  updateVariables: (
    update: (variables: Record<string, unknown>) => Record<string, unknown>,
  ) => void
  mutations: Record<string, Function>
}

export type RenderComponentProps = {
  component: ComponentModel
  components: ComponentModel[]
  dataSignal: Signal<ComponentData>
  onEvent: (event: string, data: unknown) => void
  isRootComponent: boolean
  id: string
  children: Element[]
}
export const renderComponent = ({
  component,
  dataSignal,
  onEvent,
  isRootComponent,
  id,
  children,
  components,
}: RenderComponentProps): Element[] => {
  const cleanUp: Function[] = []

  const ctx: ComponentContext = {
    triggerEvent: onEvent,
    component,
    components,
    dataSignal,
    isRootComponent,
    mutations: {},
    queries: {},
    children,
  }

  component.queries.forEach((q) => {
    if (q.autoFetch ?? q.type === 'query') {
      ctx.queries[q.name] = createQuery(q, ctx)
    } else {
      ctx.mutations[q.name] = createMutation(q, ctx)
    }
  })

  const rootElem = component.root
    ? createNode({
        node: component.root,
        id: id,
        dataSignal,
        ctx,
      })
    : []

  component.onLoad?.actions.forEach((action) => {
    const cleanupFunc = handleAction(action, dataSignal.get(), ctx)
    if (typeof cleanupFunc === 'function') {
      cleanUp.push(cleanupFunc)
    }
  })
  return rootElem
}

type NodeRenderer<NodeType> = {
  node: NodeType
  dataSignal: Signal<NodeData>
  id: string
  ctx: ComponentContext
}

export const createNode = ({
  node,
  dataSignal,
  id,
  ctx,
}: NodeRenderer<NodeModel>): Element[] => {
  const create = ({ node, ...props }: NodeRenderer<NodeModel>): Element[] => {
    switch (node.type) {
      case 'element':
        return [
          createElement({
            node,
            ...props,
          }),
        ]
      case 'component':
        return createComponent({
          node,
          ...props,
        })
      case 'text':
        return [createText({ ...props, node })]
      case 'slot':
        return createSlot({ ...props, node })
    }
  }

  const conditional = ({
    node,
    dataSignal,
    id,
    ctx,
  }: NodeRenderer<NodeModel>): Element[] => {
    let elements: Element[] = []
    let childDataSignal: Signal<ComponentData> | null = null
    const template = createTemplate(id)
    const showSignal = dataSignal.map((data) =>
      toBoolean(applyFormula(node.condition, data)),
    )
    showSignal.subscribe((show) => {
      if (show && elements.length === 0) {
        childDataSignal?.destroy()
        childDataSignal = dataSignal.map((a) => a)
        elements = create({ node, dataSignal: childDataSignal, id, ctx })
        elements.forEach((elem) => {
          template.parentElement?.insertBefore(elem, template)
        })
      }
      if (show === false) {
        childDataSignal?.destroy()
        childDataSignal = null
        elements.forEach((elem) => elem.remove())
        elements = []
      }
    })
    if (showSignal.get()) {
      elements = create({ node, dataSignal, id, ctx })
    }
    return [...elements, template]
  }
  const repeat = ({
    node,
    dataSignal,
    id,
    ctx,
  }: NodeRenderer<NodeModel>): Element[] => {
    const template = createTemplate(id)
    let children: { dataSignal: Signal<NodeData>; elements: Element[] }[] = []

    const listLengthSignal = dataSignal.map(
      (data) => applyFormula(node.repeat, data)?.length,
    )

    listLengthSignal.subscribe((itemCount) => {
      typeof itemCount !== 'number' ? 0 : itemCount
      children.forEach((child) => {
        child.dataSignal.destroy()
        child.elements.forEach((elem) => elem.remove())
      })
      children = []
      for (let index = 0; index < itemCount; index++) {
        if (index >= children.length) {
          const childDataSignal = dataSignal.map(
            (data): NodeData => ({
              ...data,
              ListItem: {
                ...(data.ListItem ? { Parent: data.ListItem } : {}),
                Item: applyFormula(node.repeat, data)?.[index],
                Index: index,
              },
            }),
          )
          const args = {
            node,
            dataSignal: childDataSignal,
            id: index === 0 ? id : `${id}(${index})`,
            ctx,
          }
          const listElems = node.condition ? conditional(args) : create(args)

          listElems.forEach((elem) => {
            template.parentElement?.insertBefore(elem, template)
          })
          children.push({
            dataSignal: childDataSignal,
            elements: listElems,
          })
        }
      }
    })
    return [template]
  }

  if (node.repeat) {
    return repeat({ node, dataSignal, ctx, id })
  }
  if (node.condition) {
    return conditional({ node, dataSignal, ctx, id })
  }
  return create({ node, dataSignal, ctx, id })
}

const createTemplate = (id: string) => {
  const template = document.createElement('template')
  template.setAttribute('data-template-id', id)
  return template
}
type RenderTextProps = {
  node: TextNodeModel
  dataSignal: Signal<NodeData>
  id?: string
  ctx: ComponentContext
}

const createText = ({
  node,
  id,
  dataSignal,
  ctx,
}: RenderTextProps): HTMLElement => {
  const { value } = node
  const elem = document.createElement('span')
  if (typeof id === 'string') {
    elem.setAttribute('data-id', id)
  }
  if (ctx.isRootComponent === false) {
    elem.setAttribute('data-component', ctx.component.name)
  }
  elem.setAttribute('data-node-type', 'text')
  if (value.type !== 'value') {
    const sig = dataSignal.map((data) => String(applyFormula(value, data)))
    sig.subscribe((value) => (elem.innerText = value))
  } else {
    elem.innerText = String(value.value)
  }
  return elem
}

type RenderComponentNodeProps = {
  id: string
  node: ComponentNodeModel
  dataSignal: Signal<NodeData>
  ctx: ComponentContext
}

const createComponent = ({
  node,
  id,
  dataSignal,
  ctx,
}: RenderComponentNodeProps): Element[] => {
  const component = ctx.components?.find((comp) => comp.name === node.name)

  if (!component) {
    console.error('Could not find component', node.name)
    return []
  }
  const attributesSignal = dataSignal.map((data) => {
    return mapObject(node.attrs, ([attr, value]) => [
      attr,
      value.type !== 'value' ? applyFormula(value, data) : value.value,
    ])
  })
  const children = node.children.flatMap((child, i) =>
    createNode({
      node: child,
      id: id + '.' + i,
      dataSignal,
      ctx,
    }),
  )

  const componentDataSignal = signal<ComponentData>({
    Session: dataSignal.get().Session,
    Location: dataSignal.get().Location,
    Variables: Object.fromEntries(
      component.variables.map((variable) => [
        variable.name,
        applyFormula(variable.initialValue, {
          Props: attributesSignal.get(),
        }),
      ]),
    ),
    Props: attributesSignal.get(),
    Queries: Object.fromEntries(
      component.queries.map((q) => [
        q.name,
        { data: null, isLoading: false, error: null },
      ]),
    ),
    Functions: Object.fromEntries(
      component.functions?.map((f) => [
        f.name,
        memoize((data: NodeData) => applyFormula(f.value, data)),
      ]) ?? [],
    ),
  })

  ;(window as any).__components = {
    ...((window as any).__components ?? {}),
    [component.name]: componentDataSignal,
  }

  attributesSignal.subscribe(
    (Attributes) =>
      componentDataSignal.update((data) => ({
        ...data,
        Props: Attributes,
      })),
    () => componentDataSignal.destroy(),
  )
  return renderComponent({
    dataSignal: componentDataSignal,
    component,
    components: ctx.components,
    id,
    isRootComponent: false,
    children,
    onEvent: (eventTrigger, data) => {
      const eventHandler = node.events.find((e) => e.trigger === eventTrigger)
      window.toddle.eventLog.push({
        component: component.name,
        node: component.name,
        nodeId: id,
        event: eventTrigger,
        time: Date.now(),
        data,
      })
      if (eventHandler) {
        eventHandler.actions.forEach((action) =>
          handleAction(action, { ...dataSignal.get(), Event: data }, ctx),
        )
      }
    },
  })
}
const createDocumentElement = (tag: string) => {
  switch (tag) {
    case 'svg':
    case 'path':
    case 'rect':
    case 'circle':
    case 'polyline':
    case 'line':
      return document.createElementNS('http://www.w3.org/2000/svg', tag)

    default:
      return document.createElement(tag)
  }
}

const createElement = ({
  node,
  dataSignal,
  id,
  ctx,
}: NodeRenderer<ElementNodeModel>): Element => {
  const elem = createDocumentElement(node.tag)
  if (id) {
    elem.setAttribute('data-id', id)
  }
  if (ctx.isRootComponent === false) {
    elem.setAttribute('data-component', ctx.component.name)
  }
  const classHash = getClassName([node.style, node.variants])
  elem.classList.add(classHash)
  if (node.classList) {
    node.classList?.forEach((elemClass) => {
      if (elemClass.formula) {
        const classSignal = dataSignal.map((data) =>
          toBoolean(applyFormula(elemClass.formula, data)),
        )
        classSignal.subscribe((show) =>
          show
            ? elem.classList.add(elemClass.name)
            : elem.classList.remove(elemClass.name),
        )
      } else {
        elem.classList.add(elemClass.name)
      }
    })
  }

  Object.entries(node.attrs).forEach(([attr, value]) => {
    switch (attr) {
      case 'value':
      case 'src':
      case 'type': {
        if (value.type === 'value') {
          ;(elem as any)[attr] = value?.value
        } else {
          const o = dataSignal.map((data) => String(applyFormula(value, data)))
          o.subscribe((val) => {
            ;(elem as HTMLInputElement)[attr] = val
          })
        }
        break
      }
      default: {
        if (value.type === 'value') {
          if (node.tag.indexOf('-') === -1) {
            if (toBoolean(value?.value) === false) {
              elem.removeAttribute(attr)
            } else {
              elem.setAttribute(attr, String(value?.value))
              if (
                attr === 'autofocus' &&
                document.body.getAttribute('data-mode') !== 'design'
              ) {
                setTimeout(() => elem.focus(), 100)
              }
            }
          } else {
            ;(elem as any)[attr] = value?.value
          }
        } else {
          const o = dataSignal.map((data) => applyFormula(value, data))
          o.subscribe((val) => {
            if (node.tag.indexOf('-') === -1) {
              if (toBoolean(val) === false) {
                elem.removeAttribute(attr)
              } else {
                elem.setAttribute(attr, val)
                if (
                  attr === 'autofocus' &&
                  document.body.getAttribute('data-mode') !== 'design'
                ) {
                  setTimeout(() => elem.focus(), 100)
                }
              }
            } else {
              ;(elem as any)[attr] = val
            }
          })
        }
      }
    }
  })
  node.styleVariables?.forEach((styleVariable) => {
    if (isFormula(styleVariable.value)) {
      const sig = dataSignal.map((data) =>
        applyFormula(styleVariable.value, data),
      )
      sig.subscribe((value) =>
        elem.style.setProperty(`--${styleVariable.name}`, value),
      )
    } else {
      elem.style.setProperty(`--${styleVariable.name}`, styleVariable.value)
    }
  })
  node.events.forEach((event) => {
    const handler = (e: Event) => {
      if (event.preventDefault) {
        e.preventDefault()
      }
      if (event.stopPropagation) {
        e.stopPropagation()
      }
      window.toddle.eventLog.push({
        component: ctx.component.name,
        node: `<${node.tag}/>`,
        nodeId: id,
        event: event.trigger,
        time: Date.now(),
        data: e,
      })

      event.actions.forEach((action) => {
        if (e instanceof DragEvent) {
          ;(e as any).data = getDragData(e)
        }
        handleAction(action, { ...dataSignal.get(), Event: e }, ctx, e)
      })
      return false
    }
    elem.addEventListener(event.trigger, handler)
  })
  node.children.forEach((child, i) => {
    const childNodes = createNode({
      node: child,
      id: id + '.' + i,
      dataSignal,
      ctx,
    })
    childNodes.forEach((childNode) => elem.appendChild(childNode))
  })

  return elem
}

const createSlot = ({
  id,
  node,
  dataSignal,
  ctx,
}: NodeRenderer<SlotNodeModel>): Element[] => {
  const template = createTemplate(id)
  const children =
    ctx.children.length === 0
      ? node.children.flatMap((child, i) =>
          createNode({
            node: child,
            id: id + '.' + i,
            dataSignal,
            ctx,
          }),
        )
      : ctx.children
  return [...children, template]
}

const getDragData = (event: Event) => {
  if (event instanceof DragEvent) {
    return Array.from(event.dataTransfer?.items ?? []).reduce<
      Record<string, any>
    >((dragData, item) => {
      dragData[item.type] = event.dataTransfer?.getData(item.type)
      return dragData
    }, {})
  }
  return
}

export const handleAction = (
  action: ActionModel,
  data: NodeData,
  ctx: ComponentContext,
  event?: Event,
) => {
  if (
    action.condition &&
    toBoolean(applyFormula(action.condition, data)) === false
  ) {
    return
  }
  switch (action.type) {
    case 'Update Variable': {
      ctx.dataSignal.set({
        ...ctx.dataSignal.get(),
        Variables: {
          ...ctx.dataSignal.get().Variables,
          [action.variableName]: applyFormula(action.value, data),
        },
      })
      break
    }
    case 'Custom': {
      const handler = window.toddle.getAction(action.name)
      if (!handler) {
        console.error('Missing custom action', action.name)
      }
      try {
        const args = action.arguments?.map((arg) =>
          applyFormula(arg.formula, data),
        ) ?? [applyFormula(action.data, data)]
        return handler?.(args, ctx, event)
      } catch (err) {
        console.error('Error in Custom Action', err)
      }
      break
    }
    case 'Trigger Mutation': {
      const trigger = ctx.mutations[action.mutationName]
      if (!trigger) {
        console.error('Could not trigger the mutation ', action.mutationName)
        return
      }
      const vars = mapValues(action.variables, (variable) =>
        applyFormula(variable, data),
      )
      const res = trigger(vars)
      // res?.then?.(() =>
      //   Object.values(ctx.queries).forEach((query) => query.refetch()),
      // )

      res?.then(
        (data: any) => {
          action.onCompleted.actions.forEach((a) =>
            handleAction(a, ctx.dataSignal.get(), ctx),
          )
        },
        (err: any) => {
          action.onFailed.actions.forEach((a) =>
            handleAction(a, ctx.dataSignal.get(), ctx),
          )
        },
      )
      break
    }
    case 'Update Query': {
      window.toddle.locationSignal.set({
        ...window.toddle.locationSignal.get(),
        query: {
          ...window.toddle.locationSignal.get().query,
          [action.paramName]: applyFormula(action.value, data),
        },
      })
      break
    }
    case 'Trigger Event': {
      const detail = applyFormula(action.data, data)
      setTimeout(() => ctx.triggerEvent(action.event, detail), 0)

      break
    }
    case 'Debug':
      console.info(action.label, applyFormula(action.data, data))
      break

    default: {
      console.error('UNSUPPORTED ACTION', action, data)
    }
  }
}
