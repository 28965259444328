export const parseQuery = (queryString: string) =>
  Object.fromEntries(
    queryString
      .replace('?', '')
      .split('&')
      .filter((pair) => pair !== '')
      .map((pair: string) => {
        return pair.split('=').map(decodeURIComponent)
      }),
  )

export const stringifyQuery = (query: Record<string, unknown>) =>
  Object.entries(query)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`,
    )
    .join('&')
