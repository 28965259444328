import { NodeData } from '../ComponentModel'

export type PathOperation = {
  type: 'path'
  path: string[]
}

export type FunctionArgument = {
  name?: string
  isFunction?: boolean
  formula: Formula
}

export type FunctionOperation = {
  type: 'function'
  name: string
  arguments: FunctionArgument[]
  variableArguments?: boolean
}

export type RecordEntry = {
  name: string
  formula: Formula
}

export type RecordOperation = {
  type: 'record'
  entries: FunctionArgument[]
}

export type ValueOperation = {
  type: 'value'
  value: string | number | boolean | null | object
}

export type Formula =
  | FunctionOperation
  | RecordOperation
  | PathOperation
  | ValueOperation

export type FunctionDeclaration = {
  template: FunctionOperation
  handler: (args: unknown[], data: NodeData) => unknown
  getArgumentInputData?: (
    f: FunctionOperation,
    argIndex: number,
    input: any,
  ) => NodeData
}

export const isFormula = (f: any): f is Formula => {
  return f && typeof f === 'object' && f.type
}

export const applyFormula = (
  formula: Formula | string | number | undefined | boolean,
  input: any,
): any => {
  if (!isFormula(formula)) {
    return formula
  }
  switch (formula.type) {
    case 'value':
      return formula.value
    case 'path': {
      if (formula.path[0] === 'Functions' && formula.path.length > 1) {
        const [, functionName, ...rest] = formula.path
        const f = input.Functions?.[functionName]
        if (typeof f !== 'function') {
          console.error('could not find function', functionName)
          return null
        }
        return rest.reduce(
          (input, key) =>
            input && typeof input === 'object' ? input[key] : null,
          f(input),
        )
      }
      return formula.path.reduce(
        (input, key) =>
          input && typeof input === 'object' ? input[key] : null,
        input,
      )
    }
    case 'function': {
      const func = window.toddle.getFormula(formula.name)
      if (typeof func === 'function') {
        const args = formula.arguments.map((arg) =>
          arg.isFunction
            ? (Args: any) => applyFormula(arg.formula, { ...input, Args })
            : applyFormula(arg.formula, input),
        )
        try {
          return func(args, input)
        } catch (e) {
          console.error('Error applying formula', formula.name, e)
          return null
        }
      }
      console.error('Could not find function', formula.name, formula)
      return null
    }
    case 'record':
      return Object.fromEntries(
        formula.entries.map((entry) => [
          entry.name,
          applyFormula(entry.formula, input),
        ]),
      )

    default:
      console.error('Could not recognise formula', formula)
  }
}

export const valueToString = (item: any): string => {
  if (item === null || item === undefined) {
    return 'Null'
  }
  if (Array.isArray(item)) {
    return `[ ${item.slice(0, 1).map(valueToString)}${
      item.length > 1 ? ', ...' : ''
    } ]`
  }
  if (typeof item === 'object') {
    const keys = Object.keys(item).filter((k) => k.indexOf('__') !== 0)
    return `{ ${keys.slice(0, 3).join(', ')}${keys.length > 3 ? ', ...' : ''} }`
  }
  return String(item)
}
